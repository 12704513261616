.dzu-dropzone-wrapper {
  // display: flex;
  // align-items: center;
  width: 100%;
  margin-bottom: 10px;

  .progress-container {
    width: 100%;
    .progress-badge {
      position: absolute;
      // text-transform: none;
      right: 0;
    }
  }

  .img {
    opacity: 1;
    margin-top: auto;
    max-height: 40vh;
    max-width: 100%;
    transition: 0.5s ease;
  }

  label {
    font-size: 1rem;
  }
}

.dzu-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 40vh;
  // height: 100%;
  // overflow: scroll;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  transition: all 0.15s linear;
  // background-color: #0000001e;
  border: 3px solid #d9d9d9c2;
  border-radius: 4px;
}

.dzu-dropzoneActive {
  background-color: #deebff83;
  border-color: #e14eca;
}

.dzu-dropzoneDisabled {
  opacity: 0.5;
}

.dzu-dropzoneDisabled *:hover {
  cursor: unset;
}

.dzu-input {
  display: none;
}

.dzu-inputLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: "Helvetica", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #2484ff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
  transition: all 0.2s ease-in;
  // &:hover {
  //   color: #e14eca;
  // }
}

.dzu-inputLabelWithFiles {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  padding: 0 14px;
  min-height: 32px;
  background-color: #e6e6e6;
  color: #2484ff;
  border: none;
  font-family: "Helvetica", sans-serif;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  margin-left: 3%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dzu-previewContainer {
  padding: 40px 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  min-height: 60px;
  z-index: 1;
  // border-bottom: 1px solid #ececec;
  box-sizing: border-box;
}

.dzu-previewStatusContainer {
  display: flex;
  align-items: center;
}

.dzu-previewFileName {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.dzu-previewImage {
  width: auto;
  max-height: 40px;
  max-width: 140px;
  border-radius: 4px;
}

.dzu-previewButton {
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: 0.9;
  margin: 0 0 2px 10px;
}

.dzu-submitButtonContainer {
  margin: 24px 0;
  z-index: 1;
}

.dzu-submitButton {
  padding: 0 14px;
  min-height: 32px;
  background-color: #2484ff;
  border: none;
  border-radius: 4px;
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;
}

.dzu-submitButton:disabled {
  background-color: #e6e6e6;
  color: #333333;
  cursor: unset;
}

.dzu-img-container {
  position: relative;
  width: 100%;
  // background-color: black;
  // max-width: 400px;
}

.dzu-img-container:hover .img {
  opacity: 0.7;
}

.dzu-img-container:hover .dzu-img-overlay {
  opacity: 1;
}

.dzu-img-overlay {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transition: 0.5s ease;
}
