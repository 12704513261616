.ainz-project {
  // Overwrite some style

  .thumbnail {
    // display: inline-block;
    // margin-bottom: 10px;
    // overflow: hidden;
    // text-align: center;
    // vertical-align: middle;
    max-width: 500px;
    // box-shadow: $box-shadow;
  }

  .header-5 {
    .image-input-field {
      margin-top: 0px;
      margin-bottom: 20px;
      .input-group {
        margin-top: 0px;
      }
      .upload-btn {
        margin: 0px 0px;
      }
      .text-sm {
        margin: 7px 0px;
      }
    }
  }
}
